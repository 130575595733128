@use "/src/sass/import"
	
.container
	max-width: 700px
	display: flex
	width: 100%
	flex-direction: column
	align-items: center
	margin-top: 40px

.load-more-button-container
	display: flex
	justify-content: center
	align-items: flex-start
	margin-top: 10px