@use "/src/sass/import"

.container
	width: 100%

.record-name
	background-color: import.$col-grey-xlight
	border-top-left-radius: 10px
	border-top-right-radius: 10px
	padding: 10px
	text-align: right
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	text-align: right
