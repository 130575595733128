@use "/src/sass/import"

.top-row
	display: flex
	justify-content: space-between
	flex-direction: column
	background-color: import.$col-grey-xlight
	width: calc(100vw - import.$dashboard-left-menu-width - import.$navbar-width)
	align-self: flex-start

.button-container
	height: 0
	display: flex
	justify-content: flex-end
	z-index: 10

.icon
	height: 30px
	color: import.$col-grey-text
	background-color: import.$col-white
	cursor: pointer
	border: none
	padding: 5px
	font-size: 1.5rem
	&:hover
		background-color: white
		
.loading-container
	margin-top: 30px
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-self: center
