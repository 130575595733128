@use "/src/sass/import"

.container
	width: 100%
	background-color: import.$col-grey-xlight
	border-top-right-radius: 10px
	border-top-left-radius: 10px
	margin-bottom: 10px

.header
	padding-top: 10px
	padding-bottom: 10px
	padding-left: 3px
	font-weight: bold

.right-header
	cursor: pointer
	display: flex
	align-items: center
	gap: 3px

.left
	padding-left: 20px

.number-input
	width: 60px