$animation-enter-ms: 700
$animation-exit-ms: 1300

$max-height: 500px

\:global 
	.form-validation-sidebar-enter
		max-height: 0px
		opacity: 0

	.form-validation-sidebar-enter-active
		max-height: $max-height
		opacity: 1
		transition: all $animation-enter-ms+ms ease-in-out

	.form-validation-sidebar-exit
		max-height: $max-height
		opacity: 1

	.form-validation-sidebar-exit-active
		max-height: 0px
		opacity: 0
		transition: all $animation-exit-ms+ms ease-in

:export
	animation-enter-ms: $animation-enter-ms
	animation-exit-ms: $animation-exit-ms
