@use "/src/sass/import"

.header-container
	background-color: import.$col-grey-xlight
	height: 42px

.left-header
	display: flex
	align-items: center
	padding-left: 10px
	border-top-left-radius: 10px
	font-weight: bold

.select-mode-container
	cursor: pointer

.month-title
	height: 25px

.month-jan
	border-left: 1px solid black

.current
	background-color: import.$col-blue
	color: import.$col-white
	font-weight: bold
	border-radius: 5px