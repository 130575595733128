@use "../utils/_color" as color
@use "../utils/_const" as const


@mixin button-color($color, $color-light)
	color: $color
	border-color: $color

	&:hover
		background-color: $color-light

@mixin button-shadow
	box-shadow: 0.6px 1.3px 1.3px hsl(0deg 0% 0% / 0.38)
	&:hover
		box-shadow: 0.3px 0.6px 0.6px hsl(0deg 0% 0% / 0.49)


.base
	border-radius: 20px
	border: 1px solid
	padding: 9px 20px
	font-size: 1.0em
	font-weight: 500
	margin: 2px 0
	background-color: rgba(0,0,0,0)
	

	@include color.color-primary
	@include color.border-color-primary

	&:hover
		@include color.color-primary($opacity: 0.8)
		@include color.border-color-primary($opacity: 0.8)


	transition: all 0.1s ease

	&:focus
		outline: 0
	&:hover
		cursor: pointer

	&:disabled
		background-color: const.$col-grey-border
		color: const.$col-white
		border-color: transparent
		cursor: default
		pointer-events: none

.primary-solid
	@extend .base
	@include button-shadow
	@include color.background-color-primary
	color: const.$col-white

	&:hover
		@include color.background-color-primary($opacity: 0.9)
		color: const.$col-white !important


.grey-solid
	@extend .base
	@include button-shadow
	background-color: rgba(0, 0, 0, 0.08)
	color: const.$col-black
	border: 0px !important

	&:hover
		@include color.color-primary
		@include color.background-color-primary($opacity: 0.2)

		

.secondary
	@extend .base
	@include button-shadow
	@include color.color-secondary
	@include color.border-color-secondary

	&:hover
		@include color.color-secondary($opacity: 0.8)
		@include color.border-color-secondary($opacity: 0.8)

.grey
	@extend .base
	@include button-shadow
	@include button-color(const.$col-grey, const.$col-grey-light)
	color: const.$col-black
	&:hover
		color: const.$col-black
		border-color: const.$col-black

.red
	@extend .base
	@include button-color(const.$col-red, const.$col-red-light)
	&:hover
		color: const.$col-red
		border-color: const.$col-red

.disabled
	@extend .base
	@include button-color(const.$col-grey-text, const.$col-white)
	&:hover
		cursor: auto

.small
	@extend .base
	font-size: 0.8rem
	padding: 5px 12px

.no-outline
	@extend .base
	border: 1px solid transparent
	padding: 4px px
	box-shadow: none !important
	&:hover
		border: 1px solid transparent
		background-color: transparent
		transform: scale(1.03)

.loading
	padding: 0px
	
	&:hover
		cursor: pointer
		transform: scale(1.00)

.loading-content
	padding: 9px 20px 7px
	display: flex
	alignItems: center


.loading-small-content
		font-size: 0.8rem
		padding: 3px 10px 1px
