@use '/src/sass/import'
.container 
	display: flex
	flex-direction: column

.remove-top-padding
	margin-top: -65px

.remove-bottom-padding
	margin-bottom: -65px

.title
	font-size: 1.1rem
	font-weight: 400

.title-large
	margin-top: 20px
	margin-bottom: 10px
	border-bottom: 1px solid import.$col-grey
	font-size: 1.7rem

.content-html
	font-size: 0.9rem
