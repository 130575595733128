.container
	display: flex
	align-items: center
	gap: 10px

.search-container
	display: flex

.search-input
	margin-left: 0

.filter-no-data-container
	display: flex

.filter-no-data-label
	margin-left: 8px
