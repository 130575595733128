@use "/src/sass/import"

.months-container
	display: flex
	align-items: center
	justify-content: center
	width: 100%

.month-cell
	flex-basis: 0
	flex-grow: 1
	display: flex
	align-items: center
	justify-content: center


.controls-row
	width: 100%
	display: flex
	min-height: 50px
	margin-bottom: 10px
	align-items: center
	justify-content: space-between

.control-buttons
	display: flex
	align-items: center
	gap: 10px