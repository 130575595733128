@use "/src/sass/import"

.container
	padding: 7px 20px 7px 10px
	display: flex
	align-items: center
	justify-content: space-between
	border-right: 1px solid import.$col-grey-light
	min-height: 46px

.select-container
	@extend .container
	cursor: pointer

.button
	background-color: #f0f0f0
	border: 1px solid #ccc
	padding: 5px
	border-radius: 3px
	&:hover
		background-color: #e0e0e0

.selected
	background-color: #d0d0d0
	border-color: #999

.text
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	text-align: right
	cursor: pointer
	padding-left: 10px
