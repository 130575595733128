@use "/src/sass/import"

.container
	display: flex
	flex-direction: column
	align-items: center
	width: 100%

.row
	width: 100%

.record-name
	padding: 10px 10px 8px 10px
	margin-top: 20px
	border-radius: 10px
	border-bottom-right-radius: 0px
	background-color: import.$col-grey-xlight
	text-overflow: ellipsis
	justify-content: space-between
	overflow: hidden
	white-space: nowrap
	text-align: right
	border-right: 1px solid import.$col-grey-light

.record-checkbox
	float: left

.record-name-select-mode
	cursor: pointer

.record-name-edit-mode
	cursor: pointer

.placeholder
	height: 40px
	margin-top: 10px
	margin-bottom: 10px

.warning
	color: import.$col-orange