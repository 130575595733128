@use "/src/sass/import"

.container
	display: flex
	justify-content: space-between
	align-items: center
	width: 100%

.left
	flex-basis: 200px
	max-width: 800px
	flex-grow: 1
	flex-shrink: 3
	overflow-x: hidden

.right
	flex-basis: 500px
	flex-grow: 3
	flex-shrink: 1
