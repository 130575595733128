.container
	display: flex
	width: 100%
	min-width: 1000px
	overflow-x: scroll
	padding-left: 10px

.base
	padding: 0 10px
	flex-shrink: 1

.dueAt
	@extend .base
	flex-basis: 125px
	flex-grow: 0
.duration
	@extend .base
	flex-basis: 120px
	flex-grow: 0
.requestSignature
	@extend .base
	flex-basis: 100px
	flex-grow: 0
.userSuppliedContext
	@extend .base
	flex-basis: 100px
	flex-grow: 1
.assignedAnonEmailAnswer
	@extend .base
	flex-basis: 200px
	overflow: hidden
	flex-grow: 1
.escalationEmail
	@extend .base
	flex-basis: 200px
	overflow: hidden
	flex-grow: 1
.escalationDeltaDays
	@extend .base
	flex-basis: 70px
	flex-grow: 0
