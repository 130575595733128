.container
	box-sizing: border-box
	padding: 30px 7%
	max-width: 4000px
	overflow-x: scroll
	align-self: center
	display: flex
	flex-direction: column
	align-items: center
	width: 100%

.table
	display: flex
	flex-direction: column
	align-items: center
	width: 100%