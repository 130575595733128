@use "/src/sass/import"
@use "sass:color"

$border-radius: 5px

.container
	display: flex
	align-items: center
	color: import.$col-white

.left
	background-color: import.$col-blue
	border-top-left-radius: $border-radius
	border-bottom-left-radius: $border-radius
	padding: 2px 10px
	border-right: 1px solid color.mix(import.$col-blue, import.$col-white, 60%)

.right
	background-color: color.mix(import.$col-blue, import.$col-white, 90%)
	border-top-right-radius: $border-radius
	border-bottom-right-radius: $border-radius
	padding: 2px 10px
