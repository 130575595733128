@use "/src/sass/import"
$BALL_CREATED: 9px
$BALL_ENDED_SOLID: 15px
$BALL_ENDED_EMPTY: 12px
$LINE_WIDTH: 4px

.container
	width: 100%
	display: grid
	min-height: $BALL_ENDED_SOLID * 2
	grid-template: 1fr / 1fr
	place-items: center

.container > *
	grid-column: 1 / 1
	grid-row: 1 / 1

.escalation
	width: $BALL_ENDED_EMPTY + 1px
	height: $BALL_ENDED_EMPTY + 1px
	border-radius: 50%
	z-index: 7
	border: $LINE_WIDTH + 1 dotted import.$col-red
	background-color: none

.completed
	width: $BALL_ENDED_SOLID
	height: $BALL_ENDED_SOLID
	border-radius: 50%
	z-index: 6
	background-color: import.$col-green

.overdue
	width: $BALL_ENDED_EMPTY
	height: $BALL_ENDED_EMPTY
	border-radius: 50%
	z-index: 5
	border: $LINE_WIDTH solid import.$col-orange
	background-color: import.$col-white

.due-in-future
	width: $BALL_ENDED_EMPTY
	height: $BALL_ENDED_EMPTY
	border-radius: 50%
	z-index: 4
	border: $LINE_WIDTH solid import.$col-grey-border
	background-color: import.$col-white

.created
	width: $BALL_CREATED
	height: $BALL_CREATED
	border-radius: 50%
	z-index: 3
	background-color: import.$col-grey-border

.span-container
	width: 100%
	display: flex
	z-index: 2

.span
	width: 100%
	border-bottom: $LINE_WIDTH solid import.$col-grey-border

.span-empty
	width: 100%

.background
	width: 100%
	display: flex
	z-index: 2
	border-bottom: 2px solid rgba(0,0,0,0.03)


// loading animation

@keyframes shimmer
	0%
		border-color: import.$col-white
	50%
		border-color: import.$col-grey-light
	100%
		border-color: import.$col-white

.span-loading
	width: 100%
	border-bottom: $LINE_WIDTH solid import.$col-white
	animation: shimmer 3s linear infinite
