@use "/src/sass/import"

.form-title-container
	display: flex
	justify-content: space-between
	align-items: center
	width: 100%

.saving-indicator
	margin-left: 10px

.check
	color: import.$col-green

.error
	color: import.$col-red
.form-title
	text-align: right
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	text-align: right
	padding-top: 20px
	padding-bottom: 20px
	padding-right: 20px
	border-right: 1px solid import.$col-grey-light